<template>
  <div>
    <h3 v-if="QRerror" style="color:red;">{{ QRerror }}</h3>
    <qrcode-stream @decode="onDecode" @init="onInit" />
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "QrScanner",
  components: {
    QrcodeStream,
  },
  computed: {
    QRerror() {
      return this.$store.state.error;
    },
  },
  methods: {
    onDecode(result) {
      this.$store.dispatch("sentQRcode", { result: result });
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.$store.commit(
            "changeError",
            this.$i18n.t("error.permissionCamera")
          );
        } else if (error.name === "NotFoundError") {
          this.$store.commit(
            "changeError",
            this.$i18n.t("error.cameraNotFound")
          );
        } else if (error.name === "NotSupportedError") {
          this.$store.commit("changeError", this.$i18n.t("error.httpsError"));
        } else if (error.name === "NotReadableError") {
          this.$store.commit("changeError", this.$i18n.t("error.cameraInUse"));
        } else if (error.name === "OverconstrainedError") {
          this.$store.commit(
            "changeError",
            this.$i18n.t("error.cameraNotSuitable")
          );
        } else if (error.name === "StreamApiNotSupportedError") {
          this.$store.commit(
            "changeError",
            this.$i18n.t("error.cameraNotByBrowser")
          );
        }
      }
    },
  },
};
</script>

<style></style>
